// sidebar_controller.js
import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["sidebar", "overlay", "openButton", "closeButton"]

  connect() {
    console.log("Controller connected")
    console.log("Sidebar target:", this.hasSidebarTarget)
    console.log("Overlay target:", this.hasOverlayTarget)
    console.log("Open button target:", this.hasOpenButtonTarget)
    console.log("Close button target:", this.hasCloseButtonTarget)
    this.closeSidebar()
  }

  openSidebar() {
    console.log("openSidebar")
    this.sidebarTarget.classList.remove("-translate-x-full")
    this.overlayTarget.classList.remove("opacity-0", "pointer-events-none")
    this.overlayTarget.classList.add("opacity-100", "pointer-events-auto")
  }

  closeSidebar() {
    console.log("closeSidebar")
    this.sidebarTarget.classList.add("-translate-x-full")
    this.overlayTarget.classList.remove("opacity-100", "pointer-events-auto")
    this.overlayTarget.classList.add("opacity-0", "pointer-events-none")
  }

  toggleSidebar() {
    console.log("toggleSidebar")
    this.sidebarTarget.classList.contains("-translate-x-full") ? this.openSidebar() : this.closeSidebar()
  }
}